@import "../../global/scss/core.scss";
.order-sync-netsuite:checked {
    background-image: url("./../../Icon/checked.svg");
    background-size: 100%;
}
.order-sync-netsuite {
    border: 1px solid #6e768e;
    pointer-events: none;
}
.order-sync {
    border: 1px solid #6e768e;
    pointer-events: none;
}
.shipping-info textarea:focus {
    outline: none !important;
    border-color: #bd515f;
}
.order-sum {
    background-color: rgba($color: #940e0e, $alpha: 0.06);
}
@media only screen and (max-width: $screen-xl-min) {
}
@media only screen and (min-width: $screen-xl-max) {
}
