@import "../../global/scss/core.scss";
.breadthcrum-hr {
    border-bottom: 1px solid #f3f3f9;
    opacity: 35%;
}

@media only screen and (max-width: $screen-xl-min) {
    .breadthcrum {
        background-color: rgba($color: #f3f3f9, $alpha: 0.2);
    }
}
