.apexcharts-canvas {
    margin: auto;
}

.apexcharts-text tspan {
    fill: #6e768e;
}

button:focus {
    outline: none;
}

.react-search-box-dropdown ul li:nth-of-type(1n + 5) {
    display: none;
}

.filter-order .jwfbbd {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #6e768e;
    border-radius: 0px;
    padding-left: 2px;
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 16px;
}

.react-search-box-dropdown {
    position: absolute;
}

.react-search-box-dropdown ul li {
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 600;
}

@media only screen and (max-width: 1279px) {
    .react-search-box-dropdown {
        width: 16.666667%;
        top: 343px;
    }
}

@media only screen and (min-width: 1280px) {
    .react-search-box-dropdown {
        width: 11.333333%;
        top: 262px;
    }
}

input:focus {
    outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    background: url("/src/Icon/calendar.png") no-repeat;
    background-size: 90%;
    width: 20px;
    height: 20px;
    border-width: thin;
    outline: none;
    content: attr(data-date-format);
}

.Price_detail input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    background: url("/src/Icon/calendar.png") no-repeat;
    background-size: 70%;
    width: 20px;
    height: 20px;
    border-width: thin;
    outline: none;
}

.css-4ljt47-MenuList div {
    background-color: #f4f4fb;
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 14px;
}

.css-2b097c-container .css-1n7v3ny-option {
    color: #6e768e;
    background-color: #f6f6fa;
}

.css-2b097c-container .css-4ljt47-MenuList {
    padding-top: 0;
    padding-bottom: 0;
}

.css-2b097c-container .css-9gakcf-option {
    color: white;
    background-color: #6e768e;
}

.css-1n7v3ny-option:active {
    background-color: #6e768e;
}

.css-2b097c-container .css-yk16xz-control,
.css-2b097c-container .css-1pahdxg-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0%;
    outline: none;
    box-shadow: none;
    border-color: #6e768e;
    font-size: 14px;
}

.css-2b097c-container {
    outline: none;
}

.css-2b097c-container .css-1pahdxg-control:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-color: #6e768e;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-2b097c-container .css-1uccc91-singleValue {
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 14px;
}

.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer:hover,
.css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer {
    color: #6e768e;
}

.css-yk16xz-control .css-g1d714-ValueContainer {
    padding: 8px 8px;
}

::-webkit-scrollbar {
    width: 10px;
}

.status-select {
    font-family: "Open Sans";
    font-weight: 600;
    color: #6e768e;
}

.status-select .css-1uccc91-singleValue {
    color: #6e768e;
}

.status-select .css-tlfecz-indicatorContainer {
    color: #6e768e;
}

.status-select .css-26l3qy-menu {
    background-color: #f4f4fb;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.form-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    background-color: #ffffff;
}

.form-checkbox:checked {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.order-status .css-2b097c-container .css-yk16xz-control,
.order-status .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #6e768e;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    border-color: #d7dbe6;
    min-height: 20px;
    font-weight: 400;
}

.order-status .css-g1d714-ValueContainer {
    padding: 1px 8px;
}

.order-status .css-tlfecz-indicatorContainer {
    padding: 0;
}

.order-status .css-g1d714-ValueContainer .css-1uccc91-singleValue {
    font-weight: 400;
}

.order-status .css-4ljt47-MenuList div {
    font-weight: 400;
}

.css-1n7v3ny-option {
    background-color: #e7f2ff;
}

.css-2b097c-container .css-1n7v3ny-option {
    color: #6e768e;
    background-color: rgba(243, 243, 249, 0.5);
}

.order-status .css-1gtu0rj-indicatorContainer {
    padding: 0;
}

.order-status .css-9gakcf-option,
.order-status .css-4ljt47-MenuList div {
    padding: 4px 12px;
}

/* shipping-country  */
.shipping-country .css-2b097c-container .css-yk16xz-control,
.shipping-country .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #6e768e;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    border-color: #d7dbe6;
    min-height: 20px;
}

.shipping-country .css-g1d714-ValueContainer {
    padding: 1px 8px;
}

.shipping-country .css-tlfecz-indicatorContainer {
    padding: 0;
}

.shipping-country .css-g1d714-ValueContainer .css-1uccc91-singleValue {
    font-weight: 400;
}

.shipping-country .css-4ljt47-MenuList div {
    font-weight: 400;
}

.css-1n7v3ny-option {
    background-color: #e7f2ff;
}

.css-2b097c-container .css-1n7v3ny-option {
    color: #6e768e;
    background-color: rgba(243, 243, 249, 0.5);
}

.shipping-country .css-1gtu0rj-indicatorContainer {
    padding: 0;
}

.shipping-country .css-9gakcf-option,
.shipping-country .css-4ljt47-MenuList div {
    padding: 4px 12px;
}

/* @media only screen and (min-width: 767px) and (max-width: 800px) {
    .input-text-area {
        width: 13rem;
    }
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.wine-country .css-2b097c-container {
    width: 13rem;
}

.jodit-wysiwyg {
    color: #6e768e;
    font-weight: 600;
    font-family: Open Sans;
    font-size: 14px;
}

.jodit-container .jodit-workplace .jodit-wysiwyg,
.jodit-container:not(.jodit_inline) .jodit-workplace {
    min-height: 103px !important;
}

.jodit_sticky > .jodit-toolbar__box {
    position: relative !important;
}

.jodit-ui-group.jodit-ui-group_size_middle.jodit-ui-group_separated_true.jodit-ui-group_group_media,
.jodit-ui-group.jodit-ui-group_size_middle.jodit-ui-group_separated_true.jodit-ui-group_group_list,
.jodit-ui-group.jodit-ui-group_size_middle.jodit-ui-group_separated_true.jodit-ui-group_group_indent,
.jodit-ui-group.jodit-ui-group_size_middle.jodit-ui-group_separated_true.jodit-ui-group_group_other,
.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_image.jodit-ui-group__image,
span.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_strikethrough.jodit-ui-group__strikethrough,
.jodit-ui-group.jodit-ui-group_size_middle.jodit-ui-group_separated_true.jodit-ui-group_group_clipboard,
.jodit-ui-group.jodit-ui-group_size_middle.jodit-ui-group_separated_true.jodit-ui-group_group_insert {
    display: none;
}

.pagenotfound {
    line-height: 2.25rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

/* condition selectbox */
.condition-div .css-2b097c-container .css-yk16xz-control,
.condition-div .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #d7dbe6;
    border-radius: 8px;
    background-color: #f6f6fa;
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;
    min-height: 20px;
    color: #6e768e;
}

.condition-div .css-1wa3eu0-placeholder {
    color: #6e768e;
}

.condition-div .css-yk16xz-control .css-g1d714-ValueContainer,
.condition-div .css-g1d714-ValueContainer {
    padding-top: 0;
    padding-bottom: 0;
}

.condition-div .css-tlfecz-indicatorContainer,
.condition-div .css-1gtu0rj-indicatorContainer {
    padding: 3px;
}

@media only screen and (max-width: 1279px) {
    .condition-div .css-2b097c-container .css-yk16xz-control,
    .condition-div .css-2b097c-container .css-1pahdxg-control {
        font-size: 12px;
    }

    .condition-div .css-yt9ioa-option,
    .condition-div .css-1n7v3ny-option {
        background-color: #f6f6fa;
        font-size: 12px !important;
        font-family: Open Sans;
        font-weight: 600;
    }

    .condition-div .css-9gakcf-option,
    .condition-div .css-4ljt47-MenuList,
    .condition-div .css-2b097c-container .css-1uccc91-singleValue {
        font-size: 12px !important;
    }
}

.no-condition .css-2b097c-container .css-yk16xz-control,
.no-condition.css-2b097c-container .css-1pahdxg-control,
.no-condition .css-2b097c-container .css-yk16xz-control,
.no-condition .css-2b097c-container .css-1pahdxg-control {
    background-color: #fff;
}

.condition-div .css-9gakcf-option {
    text-align: left;
}

.condition-div .css-yt9ioa-option,
.condition-div .css-1n7v3ny-option {
    background-color: #f6f6fa;
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;
    text-align: left;
}

.css-26l3qy-menu {
    font-size: 14px;
}

.order-status.customer-select-status .css-2b097c-container {
    width: 9rem;
}

/* toggole button */
/* Toggle A */
input:checked ~ .dot {
    transform: translateX(100%);
    background-color: #fff;
}

input ~ .dot {
    transform: translateX(-20%);
    background-color: #fff;
}

input:checked ~ .slider {
    background-color: #bd515f;
}

.shipping-country.status-select.address-select .css-2b097c-container {
    width: 16rem;
    font-weight: 600 !important;
}

.shipping-country.status-select.address-select .css-2b097c-container .css-1uccc91-singleValue {
    font-weight: 600;
}

.phone-select .css-yk16xz-control {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;
}

/* .phone-select .css-4ljt47-MenuList,
.phone-select .css-26l3qy-menu {
    background-color: rgba(243, 243, 249, 0.8);
} */
.phone-select .css-4ljt47-MenuList div,
.phone-select .css-26l3qy-menu {
    background-color: #f4f4fb;
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 14px;
}

.phone-select .css-tlfecz-indicatorContainer {
    padding: 0;
}

.phone-select .css-2b097c-container {
    width: 7rem;
    height: 2rem;
}

.phone-select .css-1gtu0rj-indicatorContainer {
    padding: 0;
}

.phone-select .css-2b097c-container .css-yk16xz-control,
.phone-select .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-height: 32px;
    padding: 0px 0px;
}

.phone-select .css-g1d714-ValueContainer,
.phone-select .css-1hwfws3 {
    padding: 0px 8px;
}

.shipping-country.status-select.address-select .css-yk16xz-control,
.shipping-country.status-select.address-select .css-1pahdxg-control {
    min-height: 32px;
}

/* input file */
input[type="file"] {
    outline: none;

    width: 100%;
    height: 100%;
    position: absolute;
    text-indent: -999em;
}

.notification__title {
    font-family: Open Sans;
}

/* loader */

.parentDisablefull {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.4;
    z-index: 998;
    height: 100%;
    width: 100%;
}

.overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #6e768e;
    background: black;
    opacity: 1;
    z-index: 1000;
}

.Login .notification-container--top-right {
    top: -23px !important;
}

.Forgot .notification-container--top-right {
    top: -73px !important;
}

.Reset .notification-container--top-right {
    top: -80px !important;
}

.customerheight {
    height: fit-content;
    height: -moz-max-content;
    height: -webkit-max-content;
}

/* phone number css of the orderdetail page */
.phone-select.order-page .css-2b097c-container {
    font-size: 12px;
    font-weight: 400;
}

.phone-select.order-page .css-yk16xz-control {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.phone-select.order-page .css-4ljt47-MenuList div {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.phone-select.order-page .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.phone-select.order-page .css-2b097c-container {
    width: 6rem;
    height: 1.5rem;
}

.phone-select.order-page .css-tlfecz-indicatorContainer,
.phone-select.order-page .css-1gtu0rj-indicatorContainer {
    padding: 0;
    width: 16px;
}

.phone-select.order-page .css-2b097c-container .css-yk16xz-control,
.phone-select.order-page .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-height: 25px;
    padding: 0px 0px;
}

/* .phone-select.order-page .css-4ljt47-MenuList div,
.phone-select.order-page .css-26l3qy-menu {
    background-color: #f4f4fb;
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 400;
    padding-bottom: 5px;
    font-size: 13px;
} */
.jodit-status-bar div:first-child {
    display: none;
}

.Product-Detail .notification-container--top-right {
    right: 85px !important;
}

.notification-container--top-right {
    right: 102px !important;
}

/* customisation select box */
.phone-select.order-page.custom .css-2b097c-container {
    width: 24rem;
}

.phone-select.order-page.custom .css-4ljt47-MenuList div,
.phone-select.order-page.custom .css-26l3qy-menu {
    font-weight: 400;
}

.phone-select.order-page.custom .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 14px;
}

.phone-select.order-page.custom .css-2b097c-container {
    font-size: 14px;
    font-weight: 400;
}

.phone-select.order-page.custom .css-yk16xz-control {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 400;
}

.phone-select.order-page.custom .css-4ljt47-MenuList div {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 400;
}

.phone-select.order-page.custome .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 400;
}

.shipping-font {
    font-weight: 400 !important;
}

.shipping-font .css-2b097c-container .css-1uccc91-singleValue {
    font-weight: 400 !important;
}

.HomePageCustomization-main-content .order-page .css-2b097c-container {
    margin-bottom: 7px;
}

.word-breaks-list {
    word-break: break-word;
}

.condition-div .css-1wa3eu0-placeholder {
    font-weight: 400;
}

.condition-div input::placeholder {
    font-weight: 400;
}

.Shipping-size .css-2b097c-container .css-1pahdxg-control:hover {
    font-size: 14px;
}

.shipping-country.wine-country.status-select.shipping-font .css-1pahdxg-control:hover,
.shipping-country.wine-country.status-select.shipping-font .css-2b097c-container .css-yk16xz-control,
.shipping-country.wine-country.status-select.shipping-font .css-2b097c-container .css-1pahdxg-control {
    font-size: 14px;
}

/* anand 07/07/2021 */
/* phone number css of the user  page */
/* .user-select.order-page .css-2b097c-container {
    font-size: 0px;
    font-weight: 400;
}
.user-select.order-page .css-yk16xz-control {
    font-size: 0px;
    font-family: Open Sans;
    font-weight: 400;
}
.user-select.order-page .css-4ljt47-MenuList div {
    font-size: 0px;
    font-family: Open Sans;
    font-weight: 400;
}
.user-select.order-page .css-2b097c-container .css-1uccc91-singleValue,
.user-select.order-page .css-2b097c-container.css-tlfecz-indicatorContainer {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
    top: 10px;
}
.user-select.order-page .css-2b097c-container.css-1gtu0rj-indicatorContainer {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
    padding-bottom: 20px;
}
.user-select .css-1gtu0rj-indicatorContainer {
    bottom: 10px;
}
.user-select .css-4ljt47-MenuList div,
.user-select .css-yk16xz-control .css-26l3qy-menu {
    background-color: #f4f4fb;
    color: #6e768e;
    font-family: Open Sans;
    font-weight: 600;
    padding-bottom: 3px;
    font-size: 14px;
    max-width: 100%;
}
.user-select.order-page .css-2b097c-container {
    width: 5rem;
}
.user-select.order-page .css-1hb7zxy-IndicatorsContainer .css-tlfecz-indicatorContainer,
.user-select.order-page .css-1hb7zxy-IndicatorsContainer .css-1gtu0rj-indicatorContainer {
    padding: 0;
    width: 16px;
}
.user-select .css-yk16xz-control .css-g1d714-ValueContainer {
    padding: 6px 6px;
}
.user-select .css-1pahdxg-control .css-g1d714-ValueContainer {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;
}
.user-select .css-1pahdxg-control .css-g1d714-ValueContainer .css-b8ldur-Input {
    padding-bottom: 25px;
    max-height: 2px;
    color: white;
}
.user-select .css-2b097c-container .css-yk16xz-control,
.user-select .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-height: 14px;
}
.user-select .css-1pahdxg-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0%;
    outline: none;
    box-shadow: none;
    border-color: #6e768e;
    font-size: 14px;
    max-height: 20px;
}

.user-select .css-1hb7zxy-IndicatorsContainer .css-1gtu0rj-indicatorContainer:hover,
.user-select .css-1hb7zxy-IndicatorsContainer .css-1gtu0rj-indicatorContainer {
    color: #6e768e;
    margin-bottom: 21px;
} */
.user-select.order-page .css-2b097c-container {
    font-size: 12px;
    font-weight: 400;
}

.user-select.order-page .css-yk16xz-control {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page .css-4ljt47-MenuList div {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page.css-2b097c-container {
    width: 6rem;
    height: 1.5rem;
}

.user-select.order-page .css-tlfecz-indicatorContainer,
.user-select.order-page .css-1gtu0rj-indicatorContainer {
    padding: 0;
    width: 16px;
    color: #6e768e;
}

.user-select.order-page .css-2b097c-container .css-yk16xz-control,
.user-select.order-page .css-2b097c-container .css-1pahdxg-control {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-height: 25px;
    padding: 0px 0px;
}

.user-select.order-page .css-yk16xz-control .css-g1d714-ValueContainer,
.user-select.order-page .css-yk16xz-control .css-1hwfws3,
.user-select.order-page .css-g1d714-ValueContainer {
    padding: 0px 8px;
}

.user-select.order-page .css-2b097c-container .css-yk16xz-control,
.css-2b097c-container .css-1pahdxg-control {
    font-size: 10px;
}

.user-select.order-page .css-2b097c-container {
    width: 7rem;
}

.user-select.order-page .css-4ljt47-MenuList div,
.user-select.order-page .css-26l3qy-menu {
    font-weight: 400;
}

.user-select.order-page .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 14px;
}

.user-select.order-page .css-2b097c-container {
    font-size: 10px;
    font-weight: 400;
}

.user-select.order-page .css-yk16xz-control {
    font-size: 10px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page .css-4ljt47-MenuList div {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page.adduser .css-4ljt47-MenuList div {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page.adduser .css-2b097c-container .css-1uccc91-singleValue {
    font-size: 13px;
}

.user-select.order-page.adduser .css-2b097c-container {
    width: 6.7rem;
}

.user-select.order-page.adduser .css-2b097c-container {
    font-size: 12px;
    font-weight: 400;
}

.user-select.order-page.adduser .css-yk16xz-control {
    font-size: 12px;
    font-family: Open Sans;
    font-weight: 400;
}

.user-select.order-page.adduser .css-2b097c-container .css-yk16xz-control,
.user-select.order-page.adduser .css-2b097c-container .css-1pahdxg-control {
    font-size: 10px;
    font-family: Open Sans;
    font-weight: 400;
}

.deivery-select .css-2b097c-container .css-yk16xz-control,
.deivery-select .css-2b097c-container .css-1pahdxg-control {
    font-size: 14px;
}

.deivery-select .css-g1d714-ValueContainer {
    padding: 0px 8px;
}

.deivery-select .css-2b097c-container,
.deivery-select .css-b8ldur-Input {
    color: #6e768e;
}

.order-status.status-select.adduser .css-2b097c-container .css-1pahdxg-control:hover,
.order-status.status-select.adduser .css-2b097c-container .css-yk16xz-control,
.order-status.status-select.adduser .css-2b097c-container .css-1pahdxg-control {
    font-size: 14px;
}

.order-status.status-select.adduser .css-g1d714-ValueContainer {
    padding: 0px 8px;
}

.Categorys-main .notification-container--top-right {
    right: 112px !important;
}

/* tooltip */
.rc-tooltip-placement-top .rc-tooltip-inner {
    padding: 0px;
    color: #fff;
    min-height: 30px;
    border: none;
    background-color: transparent !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: 2px !important;
    display: none;
}

.rc-tooltip {
    background-color: transparent !important;
}

.donut-chart {
    width: 200px;
}

.warehouse_bond .css-1wa3eu0-placeholder {
    font-size: 14px;
}
.warehouse_bond .css-2b097c-container .css-yk16xz-control,
.css-2b097c-container .css-1pahdxg-control {
    font-size: 14px;
}
