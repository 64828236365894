@import "../../global/scss/core.scss";
.HomePageCustomization-main {
    background-color: rgba($color: #f3f3f9, $alpha: 0.4);
}
.dropdown-main {
    background-color: rgba($color: #f3f3f9, $alpha: 0.6);
}
.order-sync-netsuite {
    background-image: url("./../../Icon/checked.svg");
    background-size: 100%;
}
.border-testtop::before {
    display: flex;
    align-items: center;
    content: "";
    align-self: center;
    box-sizing: border-box;
    width: 50px;
    height: 1px;
    border-top: 1px dashed #6e768e;
}
.border-testtop {
    border-left: 1px dashed #6e768e;
    height: 25px;
    top: 12px;
    position: relative;
}
.border-testbottom {
    border-left: 1px dashed #6e768e;
    height: 25px;
    position: relative;
    bottom: 12px;
}
.border-testbottom::before {
    display: flex;
    align-items: center;
    content: "";
    align-self: center;
    box-sizing: border-box;
    width: 50px;
    height: 1px;
    border-top: 1px dashed #6e768e;
    margin-top: 24px;
    position: relative;
}
// .border-hr {
//     border-left: 1px dashed #6e768e;
//     line-height: 50%;
// }
.border-hr::before {
    display: flex;
    align-items: center;
    content: "";
    align-self: center;
    box-sizing: border-box;
    width: 53px;
    height: 1px;
    border-top: 1px dashed #6e768e;
}
.border-hr {
    position: relative;
    border-left: 1px dashed #6e768e;
}
