/*font family  */
$primary-font-family: Open Sans !default;
$primary-font-family1: Lato !default;
$primary-font-family2: Noto Sans !default;
$primary-font-family3: Philosopher !default;
$primary-font-family4: Poppins !default;
$primary-font-family5: Lora !default;
$primary-font-family6: Ubuntu !default;
$primary-font-family7: Quicksand !default;

/*font weight  */
$font-weight-normal: 400;
$font-weight-bold: 700;

/* colors   */
$primary-color: #d2aa5c;
$secondary-color: #333333;
$footer-bg: #f0f0f0;
$footer-bottom-bg: #333333;
$footer-link: #483a3a;
$cookie-color: #877a67;
$button-color: #bd515f;
$Red-text: #bd515f;
$white: #fff;
$black: #000;
$home-hover: #b0976d;
$cookies-bg: #877a67;
$feature-product-head: #404040;
$expo-border-color: #707070;
$feature-icon-color: #5b7f8c;
$feature-head: #404040;
$inpur-box-bg: #f7f7f7;
$login-text: #97a3af;
$header-bg: #f0f0f0;
$contact-bg: #f0f0f0;
$horizondal-border: #b0976d;
$plp-default-color: #6e768e;
$report-text: #ced2d8;
/*font size  */
$font-sm: 7px;
$font-smm: 8px;
$font-sl: 9px;
$font-md: 10px;
$font-mdl: 12px;
$font-mdmm: 13px;
$font-mdd: 14px;
$font-ml: 16px;
$font-mdm: 25px;
$font-mdml: 27px;
$font-mds: 32px;
$font-mdx: 37px;
$font-xl: 42px;

/*paddings and spaces */

$nav-bar-top: 41px;
$nav-bar-open: 36px;
$banner-search: 22px;
$search-button: 23px;

/*width */
$mobile-menu-width: calc(100% - 40%);
$cart-width: 12px;
$count-cart-width: 16px;
$search-button-width: 150px;

/*screen width */
$screen-sm-min: 547px;
$screen-sm-max: 640px;
$screen-md-min: 767px;
$screen-md-max: 768px;
$screen-lg-min: 1023px;
$screen-lg-max: 1024px;
$screen-lg-med: 1366px;
$screen-xl-min: 1279px;
$screen-xl-max: 1280px;

/*banner-height */
$banner-height-lg: 288px;
$banner-height-xl: 711px;
$bottom-banner-xl: 513px;
$bottom-banner-md: 170px;
