@import "../../global/scss/core.scss";
.Category-main,
.Categorys-main {
    background-color: rgba($color: #f3f3f9, $alpha: 0.4);
}
.parent-cat {
    background-color: rgba($color: #f2f2f9, $alpha: 0.4);
}
.Category-Add {
    background-color: rgba($color: #f2f2f9, $alpha: 0.4);
}
// .category-check:checked {
//     background-image: url("./../../Icon/addcategory_tick.svg");
//     background-size: 100%;
// }
.category-check {
    border: 2px solid #6e768e;
    pointer-events: none;
}
.category-check:checked + label:before {
    background-image: url("./../../Icon/addcategory_tick.svg");
    color: transparent;
    content: "AS L";
    background-size: 21px;
    background-repeat: no-repeat;
    right: 14px;
    position: relative;
}
.category-check + label:before {
    color: transparent;
    content: "AS L";
    background-size: 21px;
    background-repeat: no-repeat;
    right: 14px;
    position: relative;
}
#myBar {
    width: 1%;
}
.import_page .importing_progress {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 20;
    left: 0px;
    top: 0px;
}
