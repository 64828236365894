@import "../../../global/scss/core.scss";
$progress-border-color: #d4d4d1;
$progress-border-style: solid;
$progress-border-width: 2px;

$progress-active: #16c4c7;
$progress-text-active: #222;

$progress-complete: #79bd9a;
$progress-text-complete: #79bd9a;

$progress-number-width: 33px;
$progress-number-space: 6px;

@mixin progress-alt-style($text, $color) {
    color: $text;

    > .number {
        color: #fff;
    }
}

.progress-steps {
    font-family: "Open Sans", sans-serif;

    > div {
        margin-left: $progress-number-width + $progress-number-space;
        position: relative;

        &.hidden {
            display: none;
        }

        &.active > h2::before {
            border: none;
        }

        &.complete > h2 {
            @include progress-alt-style($progress-text-complete, $progress-complete);
        }

        &:last-child > h2::before {
            border: none;
        }

        > h2 {
            font-size: 1em;
            margin-left: -($progress-number-width + $progress-number-space);
            @include progress-alt-style($progress-text-active, $progress-active);

            &::before {
                content: "";
                position: absolute;
                border-left: $progress-border-style $progress-border-width $progress-border-color;
                width: $progress-border-width;
                height: calc(100% - #{$progress-number-width - $progress-number-space});
                top: $progress-number-width;
                left: -23px;
            }

            > .number {
                border-radius: 50%;
                width: $progress-number-width;
                height: $progress-number-width;
                display: inline-block;
                text-align: center;
                line-height: $progress-number-width;
            }
        }
    }
}

.number.num_icons::before {
    content: "";
    border-radius: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    pointer-events: none;
    /* display: none; */
    position: absolute;
    top: -5px;
    right: 132px;
    bottom: 41px;
    left: -44px;
    z-index: 2;
    border-color: #bd515f;
}

@media only screen and (min-width: $screen-xl-max) {
    .order-sums::after {
        content: "=>";
        position: relative;
        float: right;
        color: #bd515f;
        bottom: 50%;
        left: 20px;
    }
}

@media only screen and (max-width: $screen-xl-max) {
    .order-sums::after {
        content: "=>";
        position: absolute;
        margin: auto;
        color: #bd515f;
        left: 63%;
        transform: rotate(90deg);
    }
}

.number.num_icons.active.one::before {
    right: 135px;
}

.progress-steps .complete h2::before {
    border-left: solid 2px #bd515f;
    top: $progress-number-width + 5;
}

.progress-steps .complete.Not h2::before {
    top: 32px;
    height: calc(100% - 37px);
}

.progress-steps .actives h2::before {
    top: 38px;
    height: calc(100% - 27px);
}

.progress-steps .complete.non_active h2::before {
    top: 33px;
    height: calc(100% - 27px);
}

.refund-new {
    box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    -webkit-appearance: none;
}

.addittion {
    height: 314px;
}

.refund-align {
    position: relative;
    // top: 30%;
    // -webkit-transform: translateY(-50%);
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);
}

.order-sums {
    background-color: rgba($color: #940e0e, $alpha: 0.02);
}
