@import "../../global/scss/core.scss";
.Payment-main {
    background-color: rgba($color: #f3f3f9, $alpha: 0.4);
}
.payment-table {
    background-color: rgba($color: #f4f4fb, $alpha: 0.9);
}
.table-payment {
    border-bottom: 1px solid rgba($color: #6e768e, $alpha: 0.4);
}
.payment-table-body tr:nth-child(even) {
    background-color: rgba($color: #d7d7e6, $alpha: 0.2);
}
.sync-netsuite:checked {
    background-image: url("./../../Icon/checked.svg");
    background-size: 100%;
}
.sync-netsuite {
    border: 1px solid #707070;
    pointer-events: none;
}
.active-page {
    background-color: #6e768e;
    -webkit-transition: background 1s ease-out;
    -moz-transition: background 1s ease-out;
    -o-transition: background 1s ease-out;
    transition: background 1s ease-out;
}
.parentDisable {
    height: 70vh;
}
.order-view-table {
    border-bottom: 1px solid rgba($color: #707070, $alpha: 0.4);
}
.order-border-table {
    border: 1px solid #e3e3e3;
}
.table-order-mobile tr:nth-child(odd) {
    background-color: rgba($color: #f8f8ff, $alpha: 0.6);
}
.sort-by-text {
    background-color: rgba($color: #6e768e, $alpha: 0.6);
    color: $white;
}
.sort-by {
    background-color: rgba($color: #6e768e, $alpha: 0.2);
    color: $white;
}
.sortfilterborder {
    border-bottom: 1px solid rgba($color: #717781, $alpha: 0.4);
}
@media only screen and (max-width: $screen-xl-min) {
}
@media only screen and (min-width: $screen-xl-max) {
}
