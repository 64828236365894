@import "../../global/scss/core.scss";
// .sidebar {
//     box-shadow: 0px 3px 6px #00000029;
// }
.activelink {
    color: $Red-text;
}
.header-hr {
    border-bottom: 1px solid #f3f3f9;
    opacity: 35%;
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(5px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@media only screen and (min-width: $screen-xl-max) {
    .user-text {
        font-size: $font-mdmm;
    }

    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        // -webkit-animation-name: fadeInLeft;
        // animation-name: fadeInLeft;
    }
    .animate {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        // -webkit-animation-name: fadeInRight;
        // animation-name: fadeInRight;
    }
}
.sidebar {
    transition: left 0.5s;
    height: 100%;
    left: -100%;
    overflow: auto;
    top: 0px;
    width: 21rem;
    z-index: 99;
}
.nav-navbar-open .sidebar-main-lg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 20;
    left: 0px;
    top: 0px;
}
.nav-navbar-open .sidebar {
    left: 0;
    width: 20rem;
}
@media only screen and (min-width: $screen-md-min) and (max-width: $screen-xl-min) {
    .sidebar {
        transition: left 0.5s;
        height: 100%;
        left: -100%;
        overflow: auto;
        top: 0px;
        width: 21rem;
        z-index: 99;
    }
    .nav-navbar-open .sidebar-main-lg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.4);
        z-index: 20;
        left: 0px;
        top: 0px;
    }
    .close-btn {
        transition: left 0.6s;
        left: 21rem;
    }
    .wineye-dash-text {
        display: block;
    }
    .icon-img {
        padding-right: 2rem;
        padding-left: 1rem;
    }
}
.close-btn {
    transition: left 0.6s;
    left: 21rem;
}
@media only screen and (max-width: $screen-md-min) {
    .sidebar {
        transition: left 0.6s;
        height: 100%;
        left: -100%;
        overflow: auto;
        top: 0px;
        width: 21rem;
        z-index: 99;
    }

    .wineye-dash-text {
        display: block;
    }
    .nav-navbar-open .sidebar-main-lg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.66);
        z-index: 20;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
    .icon-img {
        padding-right: 2rem;
        padding-left: 1rem;
    }
    .nav-navbar-open .sidebar {
        left: 0;
    }
}
.log-main {
    background-color: rgba($color: #f3f3f9, $alpha: 0.4);
}
.user-icon {
    min-width: 9rem;
}
.login-drop.down {
    animation-name: dropdown;
    animation-duration: 0.5s;
}
@keyframes dropdown {
    0% {
        transform: translateY(-2em) scale(0);
    }
    100% {
        transform: translateY(0%) scale(1);
    }
}
