@import "../../global/scss/core.scss";
.Dashboard {
    background-color: rgba($color: #f3f3f9, $alpha: 0.4);
}
.sales-report-text {
    font-size: $font-md;
    color: $report-text;
}
.activeorder {
    background-color: $expo-border-color;
    color: $white;
}
.image-upload {
    background-color: #007bff;
}
.cron-finish {
    background-color: #28a745;
}
.cron-queue {
    background-color: #6c757d;
}
.cron-Failed {
    background-color: #cc2900;
}
.table-border {
    border: 1px solid rgba($color: #707070, $alpha: 0.1);
}
@media only screen and (max-width: $screen-xl-min) {
    .net-amount-price {
        font-size: 25px;
    }
}
@media only screen and (min-width: $screen-xl-max) {
    .net-amount-price {
        font-size: 27px;
    }
}
